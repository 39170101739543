import { ServiceFactory } from '@/services/ServiceFactory'
import ZbPromotionalCodeEditComponent from './edit-promotional-code'
const _promoCodeService = ServiceFactory.get('PromoCodesService')
const _barsService = ServiceFactory.get('BarsService')
export default {
  components: {
    ZbPromotionalCodeEditComponent
  },
  data () {
    return {
      dialog: false,
      dialog2: false,
      loading: false,
      headers: [
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'code'
        },
        { text: 'Texto promocional', value: 'promoText' },
        { text: 'Descripción', value: 'description' },
        { text: 'Negocio(s)', value: 'barNames' },
        { text: 'Fecha inicio', value: 'dateFrom' },
        { text: 'Fecha final', value: 'dateTo' },
        { text: 'Opciones', value: 'options', sortable: false }
      ],
      codes: [],
      postData: {
        barIds: [],
        code: '',
        provider: '',
        description: '',
        promoText: '',
        dateFrom: '',
        dateTo: ''
      },
      companies: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [],
      menu: false,
      currentItem: null
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.currentItem = null
      try {
        this.companies = await _barsService.listBar()
        const promoCodeResponse = await _promoCodeService.index()
        this.codes = promoCodeResponse.promoCode
        console.log(promoCodeResponse)
      } catch (error) {
        console.log(error)
      }
    },
    async submit () {
      this.loading = true
      try {
        this.postData.dateFrom = this.dates[0]
        this.postData.dateTo = this.dates[1]
        console.log(this.postData)
        await _promoCodeService.create(this.postData)
        this.dialog = false
        this.loading = false
        this.initialize()
        this.postData = {
          zbBarId: '',
          code: '',
          provider: '',
          description: '',
          promoText: '',
          dateFrom: '',
          dateTo: ''
        }
        this.dates = []
        this.$notify({
          type: 'success',
          text: 'Código promocional guardado correctamente'
        })
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al guardar el código promocional'
        })
      }
    },
    editItem (item) {
      console.log(item)
      this.currentItem = item
      this.dialog2 = true
    },
    closeModal2 () {
      this.dialog2 = false
      this.initialize()
    },
    deleteItem (item) {
      this.$swal({
        title: '¿Está seguro de eliminar el código "' + item.code + '"?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _promoCodeService.destroy(item.id)
            this.$swal(
              'Producto eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: 'Código promocional no se pudo eliminar.'
            })
          }
        }
      })
    }
  }
}
