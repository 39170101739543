import { ServiceFactory } from '@/services/ServiceFactory'
const _promoCodeService = ServiceFactory.get('PromoCodesService')
const _barsService = ServiceFactory.get('BarsService')
export default {
  data () {
    return {
      loading: false,
      postData: {
        barIds: [],
        code: '',
        provider: '',
        description: '',
        promoText: '',
        dateFrom: '',
        dateTo: ''
      },
      companies: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates: [],
      menu: false
    }
  },
  props: {
    itemData: Object
  },
  watch: {
    itemData: {
      handler: 'setItem',
      immediate: true
    }
  },
  methods: {
    async setItem () {
      if (this.itemData) {
        this.postData = this.itemData
      }
      this.dates = [this.postData.dateFrom, this.postData.dateTo]
      try {
        this.companies = await _barsService.listBar()
      } catch (error) {
        console.log(error)
      }
    },
    closeModal () {
      this.$emit('closeModal')
      this.loading = false
    },
    async submit () {
      this.loading = true
      console.log(this.itemData)
      try {
        this.postData.dateFrom = this.dates[0]
        this.postData.dateTo = this.dates[1]
        await _promoCodeService.edit(this.postData.id, this.postData)
        this.loading = false
        this.$notify({
          type: 'success',
          text: 'Código promocional editado correctamente'
        })
        this.$emit('closeModal')
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al editar el código promocional'
        })
      }
    }
  }
}
